@tailwind base;
@tailwind components;
@tailwind utilities;

.intro-content{
        display:flex;
        justify-content: center;

}
#intro-div{
        border-radius: 40px;
        margin-top: 10%;
        
}
.image-div{
        padding-top: 15%;
        padding-left: 15%;
}
.intro-title{
        font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
        font-weight: lighter;
}
