@tailwind base;
@tailwind components;
@tailwind utilities;

.card-section{
        border-radius: 20px;
        margin:12px;
        transition:300ms ease-in-out; 
        scroll-behavior: smooth;
        animation: scroll 0s linear infinite;
}
.card-section:hover{
        width:390px;
        height: auto;
        border-radius: 20px;
        margin:10px;
        transition-duration: .2s;
}
.card-image{
        border-radius: 20px;
}
.card-main-row{
        
        display:grid;
        grid-auto-flow: column;
        grid-auto-columns: auto;
        overflow-x: auto;
        overscroll-behavior-inline: contain;
        margin-bottom: 3%;
        


}
.card-main-row::-webkit-scrollbar{
        display:none;
}
.card-title{
        font-size: 40px;
        font-weight: 100;
        margin-left: 15%;
        margin-bottom: 30px;
}



@keyframes scroll {
        0% {
          transform: translateX(0);
        }
        100% {
          transform: translateX(-1500%);
        } }

