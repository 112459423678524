@tailwind base;
@tailwind components;
@tailwind utilities;

body{
        margin: 0%;
        background-repeat: no-repeat;
        background-attachment: scroll;
        background-color: black;
        background-position-x: center;
        color: white;
        cursor: url('/public/mpc.png'), auto;

        
        
}