@tailwind base;
@tailwind components;
@tailwind utilities;

.content-list{
        height: auto;
        
}
.gallery{
  height: 60vmin;
  display: flex;
  gap: 5px;
}
.gallery img{
  height: 100%;
  flex:1;
  object-fit: cover;
  overflow: hidden;
  border-radius: 9px;
  transition: all 0.5s;
  
} 
.gallery img:hover{
  flex: 5;
}
#overview{
  justify-content: center;
  width: 100%;
}
      