.main {
          display: flex;
          justify-content: center;
          align-items: center;
          height: 100vh; 
          width: 100%;
          background-color: black; 
          position: fixed;
          top: 0;
          left: 0;
          z-index: 1000; 
        }
        
        .loader-image {
          max-width: 100%;
          max-height: 100%;
        }
        