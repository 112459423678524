@tailwind base;
@tailwind components;
@tailwind utilities;

.footer-title-main{
        font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
        font-weight: bold;
}
.footer-title-sub{
        font-size:25px;
}
.footer-right{
        float: right;
        padding-right:15%;
}
.footer-left{
        float: left;
        padding-left:15% ;
}
.main-footer{
        margin-top: 20%;
        
}