body{
          margin: 0%;
          background-repeat: no-repeat;
          background-attachment: scroll;
          background-image: url(Images/fullBG.png);
          background-color: black;
          background-position-x: center;
          color: white;
          cursor: url('/public/mpc.png'), auto;
  
          
        
}
::-webkit-scrollbar {
        width: 8px;
      }
      
      ::-webkit-scrollbar-track {
        box-shadow: inset 0 0 12px black; 
        border-radius: 10px;
      }
       
      ::-webkit-scrollbar-thumb {
        background: #001072; 
        border-radius: 10px;
        cursor: grab;
      }
      
      ::-webkit-scrollbar-thumb:hover {
        background: #00093E; 
      }